import React, { useEffect, useState } from 'react'
import Api from '../../../../../Api'
import moment from 'moment'
import imageHandle from '../../../../../utils/imageHandle'
import { AiOutlineDownload } from 'react-icons/ai'

const Riwayat = () => {
    const [data, setData] = useState([])
    const getData = async () => {
        try {
            await Api.GetRiwayatPAK(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results.data
                setData(res)
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            <div className='my-2 space-y-3'>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[50px] max-w-[50px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>No</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Periode Pengajuan Angka Kredit</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>AK SKA</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>AK Pendidikan</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>AK Kumulatif</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Dokumen PAK</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Dokumen PAK Kumulatif</h1>
                            </div>
                        </div>
                        {data && data.map((val, index) => (
                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[50px] max-w-[50px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{index + 1}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(val?.laporanTahunan?.start_date).format("D MMMM YYYY")} -{moment(val?.laporanTahunan?.end_date).format("D MMMM YYYY")}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>{val?.ak_ska ?? "-"}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>{val?.ak_pendidikan ?? "-"}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>{val?.ak_kumulatif ?? "-"}</h1>
                                </div>
                                <div className='flex justify-center min-w-[200px] max-w-[200px]'>
                                    <a href={imageHandle(val?.dokumenKumulatif?.path)} download={val?.dokumenKumulatif?.file_name} target='_blank' className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit' rel="noreferrer">
                                        <AiOutlineDownload className='text-sm text-blue-thick' />
                                        <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Dokumen</span>
                                    </a>
                                </div>
                                <div className='flex justify-center min-w-[200px] max-w-[200px]'>

                                    <a href={imageHandle(val?.dokumenPeriode?.path)} download={val?.dokumenPeriode?.file_name} target='_blank' className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit' rel="noreferrer">
                                        <AiOutlineDownload className='text-sm text-blue-thick' />
                                        <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Dokumen</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <div className='px-[14px] py-[8px]'>
                    {/* <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalData}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        /> */}
                </div>
            </div>
        </div>
    )
}

export default Riwayat
