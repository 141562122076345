import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BiExport } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import { IconLocFingerprint } from '../../../../../assets'
import { Modal } from '../../../../../components'
import ExportLaporanPeriodik from '../../../../../export/ExportLaporanPeriodik'
import imageHandle from '../../../../../utils/imageHandle'

const LaporanKinerjaSaya = ({ idSkp }) => {
    const navigate = useNavigate()

    const [dataLaporan, setDataLaporan] = useState([])

    const [laporanId, setLaporanId] = useState('')
    const getLaporanKinerjaSaya = async () => {
        try {
            await Api.GetLaporanKinerjaSaya(localStorage.getItem('hris-token'), idSkp).then((response) => {
                console.log("ini", response.data.results.data)
                setDataLaporan(response.data.results.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [data, setData] = useState([])

    const getDataExportLaporanPeriodik = async (idLaporan) => {
        try {
            await Api.GetExportLaporanTahunan(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                console.log(response.data.results)
                const res = response.data.results
                setData(res)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [dokumenExport, setDokumenExport] = useState()
    const handleFile = (file) => {
        setDokumenExport(file)
    }

    const handleSubmit = (id) => {
        getDataExportLaporanPeriodik(id)
        setLaporanId(id)
        setVerifikasi(true)
    }

    const [verifikasi, setVerifikasi] = useState(false)
    const [passphrase, setPassphrase] = useState("")
    const submitPassprashe = async () => {
        try {
            const formData = new FormData();
            formData.append('passphrase', passphrase);
            formData.append('file', dokumenExport, 'document.pdf');
            await Api.PostPassphraseLaporanPeriodik(localStorage.getItem('hris-token'), laporanId, formData).then((response) => {
                toast.success('Berhasil menandatangani dokumen!')
                setVerifikasi(false)
                // navigate('/laporan-kinerja/detail-saya', { state: { id: skpId } })
            }).catch((error) => {
                console.log(error.response.data.message)
                setVerifikasi(false)
                toast.error(error.response.data.message ?? 'Gagal menandatangani dokumen!')
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [passwordType, setPasswordType] = useState('password')
    const ChangePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    useEffect(() => {
        getLaporanKinerjaSaya()
    }, [])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={verifikasi}
                    title={''}
                    buttonClose={() => setVerifikasi(false)}
                    width={'550px'}
                    content={
                        <div className='space-y-6'>
                            <div className="text-center text-sky-950 text-base font-semibold">Apakah anda ingin menandatangani surat tersebut ?</div>
                            <div className='relative'>
                                <input type={passwordType} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='passpharse' autoComplete='off' />
                                <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                    {passwordType === 'text' ?
                                        <AiOutlineEye />
                                        :
                                        <AiOutlineEyeInvisible />
                                    }
                                </button>
                            </div>
                            <div className='flex items-center justify-center gap-[12px] mt-5'>
                                <button onClick={() => setVerifikasi(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                {/* <button onClick={submitPassprashe} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>Tanda tangan</button> */}
                                <ExportLaporanPeriodik data={data} fileExport={handleFile} onClick={submitPassprashe} />
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='mt-[44px] mb-[44px] overflow-auto'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[15px] min-w-[80px] max-w-[80px]'>
                            <h1 className='text-grey-thick text-xs font-medium'>No</h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                            <h1 className='text-grey-thick text-xs font-medium'>Periode Laporan </h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-grey-thick text-xs font-medium'>Sasaran Kerja</h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-grey-thick text-xs font-medium'>Perilaku Kerja</h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-xs font-[600]'>Sebutan Nilai Kinerja</h1>
                        </div>
                        <div className=''>
                            <h1 className='text-dark-5 text-xs font-[600]'>Aksi</h1>
                        </div>
                    </div>
                    {dataLaporan && dataLaporan.map((val, index) => (
                        <div key={index} className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[80px] max-w-[80px]'>
                                <h1 className='text-grey-thick text-xs font-medium'>{index + 1}</h1>
                            </div>
                            <div className='min-w-[300px] max-w-[300px]'>
                                <h1 className='text-grey-thick text-xs font-medium line-clamp-1'>{val.bulan ? val.bulan : "Laporan Kinerja Priodik"}</h1>
                                <h1 className='text-dark-5 text-xs line-clamp-1'>{moment(val?.start_date).format("D MMMM YYYY")} s/d {moment(val?.end_date).format("D MMMM YYYY")}</h1>
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                {!val.bulan ?
                                    <>
                                        <p className='font-semibold'>{val.realisasi_kualitas ? val?.realisasi_kualitas : "-"}</p>
                                        {/* <h1 className={`${val?.sasaran_laporan_kinerja === 'Sangat Baik' ? 'bg-[#AED96C]' : val?.sasaran_laporan_kinerja === 'Baik' ? 'bg-[#51A7F5]' : val?.sasaran_laporan_kinerja === 'Butuh Perbaikan' ? 'bg-[#F5BD41]' : val?.sasaran_laporan_kinerja === 'Kurang' ? 'bg-[#7E93FF]' : val?.sasaran_laporan_kinerja === 'Sangat Kurang' ? 'bg-[#EA5A54]' : ''} text-white font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>{val?.sasaran_laporan_kinerja}</h1> */}
                                    </>
                                    : <h1 className={`${val.sasaran_laporan_kinerja === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : val.sasaran_laporan_kinerja === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : val.sasaran_laporan_kinerja === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center px-[8px] py-[8px] w-fit`}>{val.sasaran_laporan_kinerja ? val.sasaran_laporan_kinerja : "-"}</h1>
                                }
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                {!val.bulan ?
                                    <>
                                        <p className='font-semibold'>{val.nilai_perilaku ? val?.nilai_perilaku : '-'}</p>
                                        {/* <h1 className={`${val?.perilaku_kerja === 'Sangat Baik' ? 'bg-[#AED96C]' : val?.perilaku_kerja === 'Baik' ? 'bg-[#51A7F5]' : val?.perilaku_kerja === 'Butuh Perbaikan' ? 'bg-[#F5BD41]' : val?.perilaku_kerja === 'Kurang' ? 'bg-[#7E93FF]' : val?.perilaku_kerja === 'Sangat Kurang' ? 'bg-[#EA5A54]' : ''} text-white font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>{val?.perilaku_kerja}</h1> */}
                                    </>
                                    : <h1 className={`${val.perilaku_kerja === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : val.perilaku_kerja === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : val.perilaku_kerja === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center px-[8px] py-[8px] w-fit`}>{val.perilaku_kerja ? val.perilaku_kerja : "-"}</h1>
                                }
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                {!val.bulan ?
                                    <>
                                        <p className='font-semibold'>{val.nilai_kinerja ? val?.nilai_kinerja : "-"}</p>
                                        {/* <h1 className={`${val?.sebutan_kinerja === 'Sangat Baik' ? 'bg-[#AED96C]' : val?.sebutan_kinerja === 'Baik' ? 'bg-[#51A7F5]' : val?.sebutan_kinerja === 'Cukup' ? 'bg-[#F5BD41]' : val?.sebutan_kinerja === 'Kurang' ? 'bg-[#7E93FF]' : val?.sebutan_kinerja === 'Sangat Kurang' ? 'bg-[#EA5A54]' : ''} text-white font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>{val?.sebutan_kinerja}</h1> */}
                                    </>
                                    : <h1 className={`${val.sebutan_nilai_laporan_kinerja === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : val.sebutan_nilai_laporan_kinerja === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : val.sebutan_nilai_laporan_kinerja === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center px-[8px] py-[8px] w-fit`}>{val.sebutan_nilai_laporan_kinerja ? val.sebutan_nilai_laporan_kinerja : "-"}</h1>
                                }
                            </div>
                            <div className='flex items-center justify-center gap-1'>
                                <button
                                    onClick={() => {
                                        !val.bulan ?
                                            navigate('/laporan-kinerja/detail-saya/periode', {
                                                state: { id: val?.id, idSkp: idSkp }
                                            }) :
                                            navigate('/laporan-kinerja/detail-saya/bulanan', {
                                                state: { id: val?.id, idSkp: idSkp }
                                            })
                                    }}
                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center px-2'>
                                    <AiOutlineEye className='text-[#003049]' />
                                </button>
                                {!val?.file ?
                                    <button disabled className='w-[29px] h-[29px] bg-[#b3c3ce] rounded-[9px] flex items-center justify-center'>
                                        <BiExport className='text-blue-thick' />
                                    </button>
                                    :
                                    (
                                        val.status === "Sudah Disetujui" ?
                                            <a
                                                href={imageHandle(val.file)}
                                                download={`'SKA-${val.file}'`}
                                                target="_blank"
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center px-2' rel="noreferrer">
                                                <BiExport className='text-[#003049]' />
                                            </a>
                                            : <button disabled className='w-[29px] h-[29px] bg-[#b3c3ce] rounded-[9px] flex items-center justify-center px-2'>
                                                <BiExport className='text-blue-thick' />
                                            </button>
                                    )
                                }
                                {!val.bulan && val.status === "Sudah Disetujui" ?
                                    <button
                                        onClick={() => handleSubmit(val.id)}
                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center px-2'>
                                        <img src={IconLocFingerprint} alt='' />
                                    </button>
                                    : null
                                }
                                {/* <ExportLaporanPeriodik data={data} fileExport={handleFile} onClick={()=>handleSubmit(val.id)} /> */}
                            </div>
                        </div>
                    ))}
                </table>
            </div>
        </>
    )
}

export default LaporanKinerjaSaya