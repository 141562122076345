import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../../components'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import InputOption from '../../../../../components/Input/inputOption'
import Api from '../../../../../Api'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import LoadingPrimaryButton from '../../../../../components/Button/LoadingPrimaryButton'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'
import moment from 'moment'
import toast from 'react-hot-toast'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
const Pengajuan = () => {
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [skpId, setSkpId] = useState('')
    const [laporanId, setLaporanId] = useState('')

    const [dataSkp, setDataSkp] = useState([])
    const [dataLaporan, setDataLaporan] = useState([])

    const [data, setData] = useState([])
    const [id, setId] = useState([])

    const resetForm = () => {
        setSkpId('')
        setLaporanId('')
    }

    const getData = async () => {
        try {
            await Api.GetPengajuanPak(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results.data
                setData(res)
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getSkp = async () => {
        try {
            await Api.DropdownSKP(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results.data
                const formattedData = res.map(res => ({ value: res.id, label: `${moment(res?.start_periode).format("D MMMM YYYY")} - ${moment(res?.end_periode).format("D MMMM YYYY")}` }));
                setDataSkp(formattedData)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getLaporanKinerja = async () => {
        try {
            await Api.DropdownLaporanKinerja(localStorage.getItem('hris-token'), skpId).then((response) => {
                const res = response.data.results.data
                const formattedData = res.map(res => ({ value: res.id, label: `${moment(res?.start_date).format("D MMMM YYYY")} - ${moment(res?.end_date).format("D MMMM YYYY")}` }));
                setDataLaporan(formattedData)
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const postData = async () => {
        if (skpId === '' || skpId === null) {
            toast.error('Periode SKP wajib diisi!')
        } else if (laporanId === '' || laporanId === null) {
            toast.error('Periode Laporan Kinerja wajib diisi!')
        } else {
            try {
                const data = {
                    "sasaranKinerjaPegawaiId": skpId,
                    "laporanPeriodeId": laporanId
                }
                await Api.PostPengajuanPak(localStorage.getItem('hris-token'), data).then((response) => {
                    setShowModalAdd(false)
                    setRefresh(true)
                    resetForm()
                    toast.success("Berhasil menambahkan data!")
                }).catch((err) => {
                    if (err.response.data.errors) {
                        toast.error(err.response.data.errors)
                    } else if (err.response.data.message) {
                        toast.error(err.response.data.message)
                    } else {
                        toast.error("Data gagal ditambahkan!")
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const getDataById = async (id) => {
        setShowModalEdit(true)
        setId(id)
        try {
            await Api.GetPengajuanPakById(localStorage.getItem('hris-token'), id).then((response) => {
                const res = response.data.results.data
                console.log(res)
                setSkpId(res?.sasaranKinerjaPegawaiId)
                setLaporanId(res?.laporanTahunanId)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const updateData = async () => {
        if (skpId === '' || skpId === null) {
            toast.error('Periode SKP wajib diisi!')
        } else if (laporanId === '' || laporanId === null) {
            toast.error('Periode Laporan Kinerja wajib diisi!')
        } else {
            try {
                const data = {
                    "sasaranKinerjaPegawaiId": skpId,
                    "laporanPeriodeId": laporanId
                }
                await Api.UpdatePengajuanPak(localStorage.getItem('hris-token'), data, id).then((response) => {
                    setShowModalEdit(false)
                    setRefresh(true)
                    resetForm()
                    toast.success("Berhasil mengubah data!")
                }).catch((err) => {
                    if (err.response.data.errors) {
                        toast.error(err.response.data.errors)
                    } else if (err.response.data.message) {
                        toast.error(err.response.data.message)
                    } else {
                        toast.error("Data gagal diubah!")
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
    }

    const deleteData = async () => {
        try {
            await Api.DeletePengajuanPak(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Hapus Data Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data gagal dihapus')
        }
    }
    useEffect(() => {
        getSkp()
    }, [])

    useEffect(() => {
        getLaporanKinerja()
    }, [skpId])

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])

    return (
        <div>

            <div className='relative'>
                <Modal
                    activeModal={showModalAdd}
                    title={'Tambah Pengajuan PAK'}
                    buttonClose={() => { setShowModalAdd(!showModalAdd); resetForm() }}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='space-y-2'>
                                <InputOption data={dataSkp} onChange={(e) => setSkpId(e)} value={skpId} label={'Periode SKP'} placeholder={'Pilih periode...'} required={true} />
                            </div>
                            <div className='space-y-2'>
                                <InputOption data={dataLaporan} onChange={(e) => setLaporanId(e)} value={laporanId} label={'Periode Laporan Kinerja'} placeholder={'Pilih periode...'} required={true} />
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowModalAdd(!showModalAdd); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showModalEdit}
                    title={'Edit Pengajuan PAK'}
                    buttonClose={() => { setShowModalEdit(!showModalEdit); resetForm() }}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='space-y-2'>
                                <InputOption data={dataSkp} onChange={(e) => setSkpId(e)} value={skpId} label={'Periode SKP'} placeholder={'Pilih periode...'} required={true} />
                            </div>
                            <div className='space-y-2'>
                                <InputOption data={dataLaporan} onChange={(e) => setLaporanId(e)} value={laporanId} label={'Periode Laporan Kinerja'} placeholder={'Pilih periode...'} required={true} />
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowModalEdit(!showModalEdit); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => updateData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='my-2 space-y-3'>
                <div className='flex lg:flex-row items-center justify-start gap-3'>
                    <button onClick={() => setShowModalAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                        <AiOutlinePlus className='text-xl text-white' />
                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah Pengajuan</h1>
                    </button>
                </div>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Periode SKP</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Periode Laporan</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Status Verifikasi Administrasi</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan Verifikator Administrasi</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal Pengajuan</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal Selesai Verifikasi</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Verifikator</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Tim Penilai</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Status Verifikasi Berkas Angka Kredit</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                            </div>
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                            </div>
                        </div>
                        {data && data.map((val, index) => (
                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.user?.fullname ? val?.user?.fullname : '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(val?.sasaranKinerjaPegawai?.start_periode).format("D MMMM YYYY")} -{moment(val?.sasaranKinerjaPegawai?.end_periode).format("D MMMM YYYY")}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(val?.laporanTahunan?.start_date).format("D MMMM YYYY")} -{moment(val?.laporanTahunan?.end_date).format("D MMMM YYYY")}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className={`py-[12px] px-[12px] text-grey-thick text-xs font-semibold rounded-lg capitalize w-fit ${val?.statusVerifikasiAdministrasi === 'perbaikan' ? 'bg-[#FF9F9F]' : val?.statusVerifikasiAdministrasi === "pending" ? 'bg-[#FFED86]' : val?.statusVerifikasiAdministrasi === 'selesai' ? 'bg-[#7EFFB2]' : null}`}>{val?.statusVerifikasiAdministrasi ?? '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.keteranganVerifikatorAdministrasi || val?.keteranganVerifikatorAdministrasi !== '' ? val?.keteranganVerifikatorAdministrasi : "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.createdAt ? moment(val?.createdAt).format("D MMMM YYYY") : "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.tanggalSelesaiVerifikasi ? moment(val?.tanggalSelesaiVerifikasi).format("D MMMM YYYY") : "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.kelompokKerjaVerifikatorId ? val?.kelompokKerjaVerifikator?.user?.fullname : "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.kelompokKerjaId ? val?.kelompokKerja?.timPenilai?.fullname : "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className={`py-[12px] px-[12px] text-grey-thick text-xs font-semibold rounded-lg capitalize w-fit ${val?.statusVerifikasiBerkas === 'ditolak' ? 'bg-[#FF9F9F]' : val?.statusVerifikasiBerkas === 'diterima' ? 'bg-[#7EFFB2]' : null}`}>{val?.statusVerifikasiBerkas ?? "-"}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.keterangan || val?.keterangan !== '' ? val?.keterangan : "-"}</h1>
                                </div>
                                <div className='flex justify-center items-center w-full gap-3'>
                                    {val?.statusVerifikasiAdministrasi === 'selesai' ?
                                        <>
                                            <button
                                                onClick={() => getDataById(val?.id)}
                                                className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-blue-thick' />
                                            </button>
                                            <button
                                                onClick={() => deleteModal(val?.id)}
                                                className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]' />
                                            </button>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <div className='px-[14px] py-[8px]'>
                    {/* <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalData}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        /> */}
                </div>
            </div>
        </div>
    )
}

export default Pengajuan
