import moment from 'moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineDelete, AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlinePencil, HiPaperClip } from 'react-icons/hi';
import Select from 'react-select';
import Api from '../../../../../../Api';
import { Modal, ModalDelete } from '../../../../../../components';
import imageHandle from '../../../../../../utils/imageHandle';
import { selectStyle } from '../../../../../../utils/styles/select';
import SecondaryButton from '../../../../../../components/Button/SecondaryButton';
import LoadingPrimaryButton from '../../../../../../components/Button/LoadingPrimaryButton';
import PrimaryButton from '../../../../../../components/Button/PrimaryButton';

const LKPKT = ({ laporanId, dataPKT, dataKinerja, dropdownTugasStruktural, dropdownTugasPokok, fresh }) => {
    const [isLoading, setIsLoading] = useState(false)
    // Start Tugas Struktural
    const [showAddTugasUtama, setShowAddTugasUtama] = useState(false)
    const [showEditTugasUtama, setShowEditTugasUtama] = useState(false)
    const [isTahunan, setIsTahunan] = useState(false)

    const [fileBukti, setFileBukti] = useState([])

    const [tglKinerja, setTglKinerja] = useState('')
    const [uraianKinerja, setUraianKinerja] = useState('')
    const [waktuKinerja, setWaktuKinerja] = useState('')
    const [hasilKinerja, setHasilKinerja] = useState('')
    const [kuantitasKinerja, setKuantitasKinerja] = useState('')
    const [biayaKinerja, setBiayaKinerja] = useState('')

    const [selected, setSelected] = useState("")
    const [selectedStruktural, setSelectedStruktural] = useState("")

    const resetFormKinerja = () => {
        setTglKinerja("")
        setUraianKinerja("")
        setWaktuKinerja("")
        setHasilKinerja("")
        setKuantitasKinerja("")
        setBiayaKinerja("")
        setIsTahunan(false)
        setSelected("")
        setSelectedStruktural("")
        setFileBukti([])
    }

    const getKinerjaById = async (id) => {
        setShowEditTugasUtama(true)
        setIdKinerja(id)
        try {
            await Api.GetKinerjaById(localStorage.getItem('hris-token'), id).then((res) => {
                const data = res.data.results.data
                console.log(data)
                setTglKinerja(data?.tanggal)
                setUraianKinerja(data?.uraian_kegiatan)
                setWaktuKinerja(data?.waktu)
                setHasilKinerja(data?.hasil)
                setKuantitasKinerja(data?.realisasi_kuantitas)
                setBiayaKinerja(data?.realisasi_biaya)
                setIsTahunan(data?.isLaporanTahunan)
                setSelected({
                    "value": data?.rencanaAksi?.id,
                    "label": data?.rencanaAksi?.name
                })
                setSelectedStruktural({
                    "value": data?.rencanaHasilKerja?.id,
                    "label": data?.rencanaHasilKerja?.name
                })
                setFileBukti(data?.files)
            }).catch((err) => {
                console.log(err)
                toast.error(err?.response?.data?.message ?? err?.response?.data?.errors)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    const postDataKinerja = async () => {
        setIsLoading(true)
        try {
            const data = {
                tanggal: tglKinerja,
                uraian_kegiatan: uraianKinerja,
                waktu: waktuKinerja,
                hasil: hasilKinerja,
                isLaporanTahunan: isTahunan,
                laporanId: laporanId,
            }
            const dataTahunan = {
                rencanaAksiId: selected.value ?? "",
                rencanaHasilKerjaId: selectedStruktural.value ?? "",
                realisasi_kuantitas: kuantitasKinerja,
                realisasi_biaya: biayaKinerja
            }
            const formData = new FormData();

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (isTahunan) {
                for (const key in dataTahunan) {
                    if (dataTahunan.hasOwnProperty(key)) {
                        formData.append(key, dataTahunan[key]);
                    }
                }
            }
            if (isTahunan && fileBukti?.length === 0) {
                toast.error("Bukti Dukung Wajib Diisi!")
            } else {
                for (const i in fileBukti) {
                    formData.append('file', fileBukti[i][0]);
                }
                for (const pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                await Api.PostKinerja(localStorage.getItem('hris-token'), formData).then((res) => {
                    setIsLoading(false)
                    fresh()
                    resetFormKinerja()
                    toast.success('Berhasil Menambahkan Data')
                    setShowAddTugasUtama(false)
                }).catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error.response.data.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error("Gagal Menambahkan Data!")
                    }
                })

            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    const updateDataKinerja = async () => {
        setIsLoading(true)
        try {
            const data = {
                tanggal: tglKinerja,
                uraian_kegiatan: uraianKinerja,
                waktu: waktuKinerja,
                hasil: hasilKinerja,
                isLaporanTahunan: isTahunan,
                laporanId: laporanId,
            }
            const dataTahunan = {
                rencanaAksiId: selected.value ?? "",
                rencanaHasilKerjaId: selectedStruktural.value ?? "",
                realisasi_kuantitas: kuantitasKinerja,
                realisasi_biaya: biayaKinerja
            }
            const formData = new FormData();

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (isTahunan) {
                for (const key in dataTahunan) {
                    if (dataTahunan.hasOwnProperty(key)) {
                        formData.append(key, dataTahunan[key]);
                    }
                }
            }

            if (isTahunan && fileBukti?.length === 0) {
                toast.error("Bukti Dukung Wajib Diisi!")
            } else {
                for (const i in fileBukti) {
                    if (!fileBukti[i].id) {
                        formData.append('file', fileBukti[i][0]);
                    }
                }
                for (const pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                await Api.UpdateKinerja(localStorage.getItem('hris-token'), formData, idKinerja).then((res) => {
                    setIsLoading(false)
                    fresh()
                    resetFormKinerja()
                    toast.success('Berhasil Mengubah Data')
                    setShowEditTugasUtama(false)
                }).catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error.response.data.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error("Gagal Mengubah Data!")
                    }
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal Mengubah Data')
        }
    }


    const [showModalDeleteKinerja, setShowModalDeleteKinerja] = useState(false)
    const [idKinerja, setIdKinerja] = useState("")
    const deleteModalKinerja = (id) => {
        setShowModalDeleteKinerja(!showModalDeleteKinerja)
        setIdKinerja(id)
    }
    const deleteKinerja = async () => {
        try {
            await Api.DeleteKinerja(localStorage.getItem('hris-token'), idKinerja).then((response) => {
                fresh()
                setShowModalDeleteKinerja(!showModalDeleteKinerja)
                toast.success('Hapus Data Berhasil!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus!')
        }
    }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFileBukti([...fileBukti, newFiles]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...fileBukti];
        newFiles.splice(index, 1);
        setFileBukti(newFiles);
    };

    const handleChange = (selectedOption) => {
        setSelected(selectedOption)
        // setIdJobLevel(data)
    }
    const handleChangeStruktural = (selectedOption) => {
        setSelectedStruktural(selectedOption)
        // setIdJobLevel(data)
    }

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [idFile, setIdFile] = useState(false)
    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdFile(id)
        fresh()
    }

    const deleteFile = async () => {
        try {
            await Api.DeleteFileKinerja(localStorage.getItem('hris-token'), idFile).then((response) => {
                fresh()
                setShowModalDelete(!showModalDelete)
                toast.success('Hapus File Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }
    // End Tugas Struktural

    // Start Prestasi Kerja Tertentu
    const [showAddPKT, setShowAddPKT] = useState(false)
    const [showEditPKT, setShowEditPKT] = useState(false)

    const [tglPKT, setTglPKT] = useState('')
    const [uraianPKT, setUraianPKT] = useState('')
    const [dasarPKT, setDasarPKT] = useState('')
    const [hasilPKT, setHasilPKT] = useState('')

    const resetFormPKT = () => {
        setTglPKT("")
        setUraianPKT("")
        setDasarPKT("")
        setHasilPKT("")
    }
    const postDataPKT = async () => {
        try {
            const data = {
                "tanggal": tglPKT,
                "uraian_kegiatan": uraianPKT,
                "dasar_penugasan": dasarPKT,
                "hasil": hasilPKT,
                "laporanId": laporanId
            }
            console.log(data)
            await Api.PostPKT(localStorage.getItem('hris-token'), data).then((res) => {
                fresh()
                resetFormPKT()
                toast.success('Berhasil Menambahkan Data')
                setShowAddPKT(false)
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error("Gagal Menambahkan Data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    const updateDataPKT = async () => {
        try {
            const data = {
                "tanggal": tglPKT,
                "uraian_kegiatan": uraianPKT,
                "dasar_penugasan": dasarPKT,
                "hasil": hasilPKT,
                "laporanId": laporanId
            }
            console.log(data)
            await Api.UpdatePKT(localStorage.getItem('hris-token'), data, idPKT).then((res) => {
                fresh()
                resetFormPKT()
                toast.success('Berhasil Mengubah Data')
                setShowEditPKT(false)
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error("Gagal Menambahkan Data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Mengubah Data')
        }
    }
    const getPKTById = async (id) => {
        try {
            await Api.GetPKTById(localStorage.getItem('hris-token'), id).then((res) => {
                const data = res.data.results.data
                setTglPKT(data?.tanggal)
                setUraianPKT(data?.uraian_kegiatan)
                setDasarPKT(data?.dasar_penugasan)
                setHasilPKT(data?.hasil)
                setIdPKT(data?.id)
            }).catch((err) => {
                console.log(err)
                toast.error(err?.response?.data?.message ?? err?.response?.data?.errors)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    const [showModalDeletePKT, setShowModalDeletePKT] = useState(false)
    const [idPKT, setIdPKT] = useState("")
    const deleteModalPKT = (id) => {
        setShowModalDeletePKT(!showModalDeletePKT)
        setIdPKT(id)
    }
    const deletePKT = async () => {
        try {
            await Api.DeletePKT(localStorage.getItem('hris-token'), idPKT).then((response) => {
                fresh()
                setShowModalDeletePKT(!showModalDeletePKT)
                toast.success('Hapus Data Berhasil!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus!')
        }
    }
    // End Prestasi Kerja Tertentu
    // useEffect(() => {
    //     getPKTById()
    // }, [])

    return (
        <div>
            <div className='relative'>
                <Modal
                    activeModal={showAddTugasUtama}
                    title={"Tambah Kinerja"}
                    buttonClose={() => { setShowAddTugasUtama(false); resetFormKinerja() }}
                    width={'550px'}
                    content={
                        <div className='space-y-5'>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTglKinerja(e.target.value)} value={tglKinerja} type="date" className='py-[10px] px-[16px] border rounded-md text-gray-500 text-sm w-full' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setUraianKinerja(e.target.value)} value={uraianKinerja} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Waktu (menit) <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setWaktuKinerja(e.target.value)} value={waktuKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Waktu...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setHasilKinerja(e.target.value)} value={hasilKinerja} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                            </div>

                            <div className='w-full'>
                                <div className='w-full space-y-[21px]'>
                                    <div>
                                        <h1 className="text-neutral-500 text-sm font-medium mb-1">Bukti Dukung {isTahunan ? <span className='text-red-primer'>*</span> : null}</h1>
                                        <label htmlFor='upload-add-file' className='cursor-pointer'>
                                            <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                                <div className='flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                        <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                    </svg>
                                                </div>
                                                <h1 className='text-red-primer text-[12px] font-medium text-center'>Upload File Disini</h1>
                                                <div className="text-gray-500 text-[8px] text-center">PDF/PNG/JPG/JPEG maks 2 MB</div>
                                            </div>
                                            <input type='file' id='upload-add-file' multiple accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => handleFileChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required />
                                        </label>
                                        {fileBukti.length > 0 && (
                                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                {fileBukti.map((val, index) => (
                                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-sm flex gap-[8px] items-center'>
                                                            <h1>{val[0]?.name}</h1>
                                                            <button
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={!isTahunan}
                                        checked={isTahunan}
                                        onChange={(e) => { setIsTahunan(e.target.checked) }}
                                    />
                                    <span className="text-[12px] text-dark-5 font-medium">Tambahkan sebagai Realisasi SKA</span>
                                </label>
                            </div>

                            {isTahunan ?
                                <div className='space-y-5'>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tugas Struktural Asisten<span className='text-red-primer'>*</span></h1>
                                        <Select
                                            value={selectedStruktural}
                                            styles={selectStyle}
                                            onChange={data => handleChangeStruktural(data)}
                                            options={dropdownTugasStruktural}
                                            placeholder="Pilih Tugas Struktural Asisten"
                                        />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tugas Pokok<span className='text-red-primer'>*</span></h1>
                                        <Select
                                            value={selected}
                                            styles={selectStyle}
                                            onChange={data => handleChange(data)}
                                            options={dropdownTugasPokok}
                                            placeholder="Pilih Tugas Pokok"
                                        />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Realisasi Kuantitas<span className='text-red-primer'>*</span></h1>
                                        <input onChange={(e) => setKuantitasKinerja(e.target.value)} value={kuantitasKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input realisasi kuantitas...' />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Realisasi Biaya<span className='text-red-primer'>*</span></h1>
                                        <input onChange={(e) => setBiayaKinerja(e.target.value)} value={biayaKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input realisasi biaya...' />
                                    </div>
                                </div>
                                : null
                            }
                            <div className='relative pb-[37px]'>
                                <div className="flex justify-end gap-[10px]">
                                    <SecondaryButton action={() => { setShowAddTugasUtama(false); resetFormKinerja() }} text={'Batal'} />
                                    {isLoading ?
                                        <LoadingPrimaryButton />
                                        : <PrimaryButton action={() => postDataKinerja()} text={'Simpan'} />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditTugasUtama}
                    title={"Edit Kinerja"}
                    buttonClose={() => { setShowEditTugasUtama(false); resetFormKinerja() }}
                    width={'550px'}
                    content={
                        <div className='space-y-5'>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTglKinerja(e.target.value)} value={tglKinerja} type="date" className='py-[10px] px-[16px] border rounded-md text-gray-500 text-sm w-full' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setUraianKinerja(e.target.value)} value={uraianKinerja} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Waktu (menit) <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setWaktuKinerja(e.target.value)} value={waktuKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Waktu...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setHasilKinerja(e.target.value)} value={hasilKinerja} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                            </div>

                            <div className='w-full'>
                                <div className='w-full space-y-[21px]'>
                                    <div>
                                        <h1 className="text-neutral-500 text-sm font-medium mb-1">Bukti Dukung {isTahunan ? <span className='text-red-primer'>*</span> : null}</h1>
                                        <label htmlFor='upload-add-file' className='cursor-pointer'>
                                            <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                                <div className='flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                        <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                    </svg>
                                                </div>
                                                <h1 className='text-red-primer text-[12px] font-medium text-center'>Upload File Disini</h1>
                                                <div className="text-gray-500 text-[8px] text-center">PDF/PNG/JPG/JPEG maks 2 MB</div>
                                            </div>
                                            <input type='file' id='upload-add-file' multiple accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => handleFileChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required />
                                        </label>
                                        {fileBukti.length > 0 && (
                                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                {fileBukti.map((val, index) => (
                                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-sm flex gap-[8px] items-center'>
                                                            <h1>{val?.originalname ?? val[0]?.name}</h1>
                                                            <button
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={!isTahunan}
                                        checked={isTahunan}
                                        onChange={(e) => { setIsTahunan(e.target.checked) }}
                                    />
                                    <span className="text-[12px] text-dark-5 font-medium">Tambahkan sebagai SKA</span>
                                </label>
                            </div>

                            {isTahunan ?
                                <div className='space-y-5'>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tugas Struktural Asisten<span className='text-red-primer'>*</span></h1>
                                        <Select
                                            value={selectedStruktural}
                                            styles={selectStyle}
                                            onChange={data => handleChangeStruktural(data)}
                                            options={dropdownTugasStruktural}
                                            placeholder="Pilih Tugas Struktural Asisten"
                                        />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tugas Pokok<span className='text-red-primer'>*</span></h1>
                                        <Select
                                            value={selected}
                                            styles={selectStyle}
                                            onChange={data => handleChange(data)}
                                            options={dropdownTugasPokok}
                                            placeholder="Pilih Tugas Pokok"
                                        />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Realisasi Kuantitas<span className='text-red-primer'>*</span></h1>
                                        <input onChange={(e) => setKuantitasKinerja(e.target.value)} value={kuantitasKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input realisasi kuantitas...' />
                                    </div>
                                    <div>
                                        <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Realisasi Biaya<span className='text-red-primer'>*</span></h1>
                                        <input onChange={(e) => setBiayaKinerja(e.target.value)} value={biayaKinerja} type="number" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input realisasi biaya...' />
                                    </div>
                                </div>
                                : null
                            }
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditTugasUtama(false); resetFormKinerja() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button
                                        onClick={updateDataKinerja}
                                        className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddPKT}
                    title={"Tambah Prestasi Kerja Tertentu"}
                    buttonClose={() => { setShowAddPKT(false); resetFormPKT() }}
                    width={'550px'}
                    content={
                        <div className='space-y-5'>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTglPKT(e.target.value)} value={tglPKT} type="date" className='py-[10px] px-[16px] border rounded-md text-gray-500 text-sm w-full' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setUraianPKT(e.target.value)} value={uraianPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Dasar Penugasan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setDasarPKT(e.target.value)} value={dasarPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Dasar Penugasan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setHasilPKT(e.target.value)} value={hasilPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowAddPKT(false); resetFormPKT() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button
                                        onClick={postDataPKT}
                                        className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditPKT}
                    title={"Edit Prestasi Kerja Tertentu"}
                    buttonClose={() => { setShowEditPKT(false); resetFormPKT() }}
                    width={'550px'}
                    content={
                        <div className='space-y-5'>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTglPKT(e.target.value)} value={tglPKT} type="date" className='py-[10px] px-[16px] border rounded-md text-gray-500 text-sm w-full' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setUraianPKT(e.target.value)} value={uraianPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Dasar Penugasan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setDasarPKT(e.target.value)} value={dasarPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Dasar Penugasan...' />
                            </div>
                            <div>
                                <h1 className='text-neutral-500 text-sm font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setHasilPKT(e.target.value)} value={hasilPKT} type="text" className='text-zinc-700 text-sm font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditPKT(false); resetFormPKT() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button
                                        onClick={updateDataPKT}
                                        className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />

                <ModalDelete
                    activeModal={showModalDeletePKT}
                    buttonClose={() => setShowModalDeletePKT(!showModalDeletePKT)}
                    submitButton={deletePKT}
                />
                <ModalDelete
                    activeModal={showModalDeleteKinerja}
                    buttonClose={() => setShowModalDeleteKinerja(!showModalDeleteKinerja)}
                    submitButton={deleteKinerja}
                />

                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteFile}
                />
            </div>
            {/* <h1 className="text-zinc-800 text-xl font-semibold">Laporan Kinerja dan Prestasi Kerja Tertentu</h1> */}
            <div className='space-y-10'>
                <div>
                    <h1 className="text-zinc-800 text-lg font-semibold">Kinerja</h1>
                    <div className='my-5 overflow-auto'>
                        <table className='min-w-full border-collapse'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    <th rowSpan={2} className='border py-3 px-5'>No.</th>
                                    <th rowSpan={2} className='border py-3 px-5'>Aksi</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Tanggal/Bulan</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Uraian Kegiatan</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Waktu</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Hasil</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Bukti Dukung</th>
                                    <th colSpan={11} className='border py-3 px-5'>Sasaran Kinerja Asisten (SKA)</th>
                                </tr>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    {['Tugas Struktural Atasan yang Diintervensi', 'Jenis Tugas', 'Tugas Struktural Asisten', 'Target', 'Tugas Pokok', 'Target Kualitas', 'Target Kuantitas', 'Target Waktu', 'Target Biaya', 'Realisasi Kuantitas', 'Realisasi Biaya'].map((header, index) => (
                                        <th key={index} className='border py-3 px-5 min-w-[200px]'>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataKinerja && dataKinerja.map((val, index) => (
                                        <tr key={index} className='border'>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{index + 1}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                                <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    <button
                                                        onClick={() => {
                                                            getKinerjaById(val?.id)
                                                        }}
                                                        className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-blue-thick' />
                                                    </button>
                                                    <button
                                                        onClick={() => deleteModalKinerja(val?.id)}
                                                        className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                            <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#113B61" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.tanggal ? moment(val?.tanggal).format("D MMMM YYYY") : "-"}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.uraian_kegiatan ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.waktu ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.hasil ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                                <ul className='list-disc text-grey-thick text-xs font-medium space-y-1'>
                                                    {val?.files && val?.files.map((file, i) => (
                                                        <li className='flex gap-2 items-center '>
                                                            <div className='bg-blue-thin rounded-md flex gap-2 items-center p-2 text-xs whitespace-nowrap'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                            <a href={imageHandle(file?.path)} download={file?.file_name} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                                <AiOutlineDownload className='text-sm text-blue-thick' />
                                                            </a>
                                                            <button
                                                                onClick={() => deleteModal(file?.id)}
                                                                className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                                <AiOutlineDelete className='text-sm text-blue-thick' />
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.rhk_atasan ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.type ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.name ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.target_rhk ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.name ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_kualitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_kuantitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_waktu ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_biaya ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.realisasi_kuantitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.realisasi_biaya ?? ""}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <button onClick={() => setShowAddTugasUtama(true)} className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg'>
                        <AiOutlinePlus className='text-lg' />
                        <span>Tambah Kinerja</span>
                    </button>
                </div>
                <div>
                    <h1 className="text-zinc-800 text-lg font-semibold">Prestasi Kerja Tertentu</h1>
                    <div className='my-5 overflow-auto'>
                        <table className='min-w-full border-collapse'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No.</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                    <th className='border py-3 px-5'>Tanggal/Bulan</th>
                                    <th className='border py-3 px-5'>Uraian Kegiatan</th>
                                    <th className='border py-3 px-5'>Dasar Pernugasan</th>
                                    <th className='border py-3 px-5'>Hasil</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataPKT && dataPKT.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{index + 1}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button
                                                    onClick={() => {
                                                        setShowEditPKT(true)
                                                        getPKTById(val?.id)
                                                    }}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-blue-thick' />
                                                </button>
                                                <button
                                                    onClick={() => deleteModalPKT(val?.id)}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#113B61" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.tanggal ? moment(val?.tanggal).format("D MMMM YYYY") : "-"}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.uraian_kegiatan}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.dasar_penugasan}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.hasil}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button onClick={() => setShowAddPKT(true)} className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg'>
                        <AiOutlinePlus className='text-lg' />
                        <span>Tambah Prestasi Kerja Tertentu</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LKPKT