import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai';
import { HiDocument, HiOutlinePencil } from 'react-icons/hi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Api from '../../../../../Api';
import { Modal, ModalDelete, Pagination } from '../../../../../components';
import InputComponent from '../../../../../components/Input/inputComponent';
import languages from '../../../../../utils/languages';
import { IconDocument } from '../../../../../assets';
// import template from './ExportLaporanKinerjaBulanan.js';

const RealisasiBuktiDukung = ({ idSkp }) => {
    const navigate = useNavigate()
    // console.log("ini idskp", idSkp)
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const [refresh, setRefresh] = useState(false)

    const [showPenilaianTahunan, setShowPenilaianTahunan] = useState(false)
    const [showAddLaporanBulanan, setShowAddLaporanBulanan] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const [data, setData] = useState([])

    const getLaporanByIdSkp = async () => {
        try {
            await Api.GetLaporanByIdSkp(localStorage.getItem('hris-token'), limit, currentPage, idSkp).then((response) => {
                setData(response.data.data)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [idLaporan, setIdLaporan] = useState(false)
    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdLaporan(id)
        setRefresh(true)
    }

    const deleteLaporan = async () => {
        try {
            await Api.DeleteLaporanBulanan(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                setRefresh(true)
                setShowModalDelete(!showModalDelete)
                toast.success('Hapus Data Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    const [showModalDeletePeriode, setShowModalDeletePeriode] = useState(false)
    const [idLaporanPeriode, setIdLaporanPeriode] = useState(false)
    const deleteModalPeriode = (id) => {
        setShowModalDeletePeriode(!showModalDeletePeriode)
        setIdLaporanPeriode(id)
        setRefresh(true)
    }
    const deleteLaporanPeriode = async () => {
        try {
            await Api.DeleteLaporanTahunan(localStorage.getItem('hris-token'), idLaporanPeriode).then((response) => {
                setRefresh(true)
                setShowModalDeletePeriode(!showModalDeletePeriode)
                toast.success('Hapus Data Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const postLaporanTahunan = async () => {
        try {
            const data = {
                "start_date": startDate,
                "end_date": endDate,
                "skpId": idSkp
            }
            await Api.PostLaporanTahunan(localStorage.getItem('hris-token'), data).then((response) => {
                toast.success('Berhasil Menambahkan Data')
                setShowPenilaianTahunan(!showPenilaianTahunan)
                navigate("/laporan-kinerja/detail-saya/realisasi-periode", {
                    state: { id: idSkp, idLaporan: response.data.results.data.id }
                })
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error("Gagal Menambahkan Data!")
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    // const exportToPDF = () => {
    //     const options = {
    //         margin: 10,
    //         filename: 'document.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     };

    //     html2pdf().from(template).set(options).save();
    //     // const report = new jsPDF('portrait','pt', 'a4')
    //     // report.html(Template).then(()=>{
    //     //     report.save('report.pdf')
    //     // })
    // };

    // Laporan Bulanan
    const [bulan, setBulan] = useState("")
    const [tahun, setTahun] = useState("")
    const [penilai1Id, setPenilai1Id] = useState("")
    const [penilai2Id, setPenilai2Id] = useState("")
    const [dataPegawai, setDataPegawai] = useState([])
    const [persetujuan, setPersetujuan] = useState(false)
    const [isOnlyAdmin, setIsOnlyAdmin] = useState(false)

    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))
            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }

    const getDetail = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then(async (response) => {
                await Api.GetApprovalSettingById(localStorage.getItem('hris-token'), response.data.results.user.id).then((res) => {
                    const data = res.data.results.data.pengaturan_persetujuan
                    setIsOnlyAdmin(res?.data?.results?.data?.pengaturan_persetujuan?.laporanKinerja?.isOnlyAdmin)
                    // laporan
                    setPenilai1Id({
                        "value": data?.laporanKinerja?.atasan1Id ?? "",
                        "label": data?.laporanKinerja?.atasan1Name ?? ""
                    })
                    setPersetujuan(data?.laporanKinerja?.atasan2Id ? true : false)
                    setPenilai2Id({
                        "value": data?.laporanKinerja?.atasan2Id ?? "",
                        "label": data?.laporanKinerja?.atasan2Name ?? ""
                    })
                }).catch((err) => {
                    console.log(err)
                })
            })
        } catch (error) {
            console.log(error)
        }
    }

    const postLaporanBulanan = async () => {
        try {
            if (!bulan.trim()) {
                toast.error("Bulan wajib diisi!")
            } else if (!isValidYear(tahun)) {
                toast.error("Tahun tidak valid!")
            } else {
                const data = {
                    "bulan": bulan,
                    "tahun": tahun,
                    "skpId": idSkp,
                    "penilai1Id": penilai1Id?.value,
                }
                if (persetujuan === true) {
                    data["penilai2Id"] = penilai2Id?.value
                }
                await Api.PostLaporanBulanan(localStorage.getItem('hris-token'), data).then((response) => {
                    toast.success('Berhasil Menambahkan Data')
                    setShowPenilaianTahunan(!showPenilaianTahunan)
                    navigate("/laporan-kinerja/detail-saya/realisasi-bulanan", {
                        state: { id: idSkp, idLaporan: response.data.results.data.id }
                    })
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error.response.data.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error("Gagal Menambahkan Data!")
                    }
                })
            }

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    function isValidYear(year) {
        // Check if the year is between 1900 and 2100
        if (parseInt(year) >= 1900 && parseInt(year) <= 2100) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        getLaporanByIdSkp()
        setRefresh(false)
    }, [refresh])

    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showPenilaianTahunan}
                    title={"Pengajuan Penilaian"}
                    buttonClose={() => setShowPenilaianTahunan(!showPenilaianTahunan)}
                    width={'550px'}
                    content={
                        <div className='space-y-6'>
                            <div className="w-[472px] text-gray-500 text-xs font-medium ">Pilih rentang periode skp yang akan dinilai</div>

                            <div className='flex items-center justify-between gap-[20px] w-full'>
                                <div className='w-full'>
                                    <h1 className='text-neutral-500 text-[11px] font-medium'>{languages.language === 'id' ? "Periode Awal" : "Start Period"}<span className='text-red-primer'>*</span></h1>
                                    <input type="date"
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-neutral-500 text-[11px] font-medium'>{languages.language === 'id' ? "Periode Akhir" : "End Period"} <span className='text-red-primer'>*</span></h1>
                                    <input type="date"
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' />
                                </div>
                            </div>

                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowPenilaianTahunan(!showPenilaianTahunan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button
                                    onClick={() => postLaporanTahunan()}
                                    className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddLaporanBulanan}
                    title={"Tambah LKPKT"}
                    buttonClose={() => setShowAddLaporanBulanan(!showAddLaporanBulanan)}
                    width={'550px'}
                    content={
                        <div className='space-y-6'>
                            <div className='grid grid-cols-2 gap-[20px]'>
                                <div className='space-y-1'>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-[9px]'>Bulan <span className='text-red-primer'> *</span></label>
                                    <div className='relative'>
                                        <select
                                            value={bulan}
                                            onChange={(e) => setBulan(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px] appearance-none'
                                        >
                                            <option selected disabled value="">Pilih Bulan...</option>
                                            <option value={1}>Januari</option>
                                            <option value={2}>Februari</option>
                                            <option value={3}>Maret</option>
                                            <option value={4}>April</option>
                                            <option value={5}>Mei</option>
                                            <option value={6}>Juni</option>
                                            <option value={7}>Juli</option>
                                            <option value={8}>Agustus</option>
                                            <option value={9}>September</option>
                                            <option value={10}>Oktober</option>
                                            <option value={11}>November</option>
                                            <option value={12}>Desember</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Tahun"}
                                        placeholder={"Masukkan Tahun..."}
                                        value={tahun}
                                        maxLength={4}
                                        min={1111}
                                        max={9999}
                                        onChange={(e) => setTahun(e)}
                                        required={true}
                                        type={"text"}
                                    />
                                </div>
                            </div>

                            <div className='w-full space-y-3'>
                                <div className='w-full space-y-1'>
                                    {isOnlyAdmin === false ?
                                        <>
                                            <label className='w-full text-grey-thick text-[12px] font-medium mb-[9px]'>Pejabat Penilai Kinerja <span className='text-red-primer'> *</span></label>
                                            <Select
                                                placeholder="Pilih nama pejabat penilai..."
                                                className='w-full text-[12px] appearance-none'
                                                value={dataPegawai.find(option => option.value === penilai1Id.value)}
                                                onChange={selectedOption => setPenilai1Id(selectedOption)}
                                                options={dataPegawai}
                                            />
                                        </>
                                        : <InputComponent
                                            disabled={true}
                                            label={"Pejabat Penilai Kinerja"}
                                            value={penilai1Id?.label}
                                            required={true}
                                            type={"text"}
                                        />
                                    }
                                </div>
                                <div className=''>
                                    {isOnlyAdmin === false ?
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox h-5 w-5 text-blue-600"
                                                value={!persetujuan}
                                                checked={persetujuan}
                                                onChange={(e) => { setPersetujuan(e.target.checked); setPenilai2Id(null) }}
                                            />
                                            <span className="text-[12px] text-dark-5 font-medium">Tambahkan Persetujuan</span>
                                        </label>
                                        : null
                                    }
                                </div>
                                {persetujuan ?
                                    <div className='w-full space-y-1'>
                                        {isOnlyAdmin === false ?
                                            <>
                                                <label className='w-full text-grey-thick text-[12px] font-medium mb-[9px]'>Persetujuan <span className='text-red-primer'> *</span></label>
                                                <Select
                                                    placeholder="Pilih nama pejabat penilai..."
                                                    className='w-full text-[12px] appearance-none'
                                                    value={penilai2Id}
                                                    onChange={selectedOption => setPenilai2Id(selectedOption)}
                                                    options={dataPegawai}
                                                />
                                            </>
                                            : <InputComponent
                                                disabled={true}
                                                label={"Persetujuan"}
                                                value={penilai2Id?.label}
                                                required={true}
                                                type={"text"}
                                            />
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowAddLaporanBulanan(!showAddLaporanBulanan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button
                                    onClick={() => postLaporanBulanan()}
                                    className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />

                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteLaporan}
                />
                <ModalDelete
                    activeModal={showModalDeletePeriode}
                    buttonClose={() => setShowModalDeletePeriode(!showModalDeletePeriode)}
                    submitButton={deleteLaporanPeriode}
                />
            </div>
            <div className='flex lg:flex-row items-center justify-start gap-3'>
                <button onClick={() => {
                    setShowAddLaporanBulanan(true)
                    getDataPegawai()
                    getDetail()
                    // navigate('/laporan-kinerja/detail-saya/realisasi-bulanan', {
                    //     state: { id: idSkp }
                    // })
                }} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                    <AiOutlinePlus className='text-xl text-white' />
                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah LKPKT</h1>
                </button>
                <button onClick={() => setShowPenilaianTahunan(!showPenilaianTahunan)} className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg text-sm'>
                    <AiOutlinePlus className='text-xl' />
                    Pengajuan Penilaian Kinerja Periodik
                </button>
                <button
                    onClick={() => navigate('/laporan-kinerja/pengajuan-inovasi')}
                    className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg text-sm'>
                    <img src={IconDocument} alt='Inovasi' />
                    Pengajuan Inovasi
                </button>
            </div>

            <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[15px] w-2/4'>
                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Periode Laporan" : "Evaluation Period"}</h1>
                        </div>
                        <div className='flex items-center gap-[15px] w-1/4'>
                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Status" : "Status"}</h1>
                        </div>
                        <div className='flex items-center justify-center gap-[15px] w-1/4'>
                            <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                        </div>
                    </div>

                    {data && data.map((val, index) => (
                        <div key={index} className='flex justify-between items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='w-2/4'>
                                <h1 className='text-grey-thick font-semibold line-clamp-1'>{val?.nama_laporan}</h1>
                                <h1 className='text-dark-5 text-xs line-clamp-1'>{moment(val?.start_date).format("D MMMM YYYY")} s/d {moment(val?.end_date).format("D MMMM YYYY")}</h1>
                            </div>
                            <div className='w-1/4'>
                                <h1 className='text-grey-thick text-sm'>{val?.status === "approved" ? "Sudah Disetujui" : val?.status}</h1>
                            </div>
                            <div className='w-1/4 flex items-center justify-center gap-[12px]'>
                                <button
                                    onClick={() => {
                                        !val.bulan ?
                                            navigate('/laporan-kinerja/detail-saya/periode', {
                                                state: { id: val?.id, idSkp: idSkp }
                                            }) :
                                            navigate('/laporan-kinerja/detail-saya/bulanan', {
                                                state: { id: val?.id, idSkp: idSkp }
                                            })
                                    }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                    <AiOutlineEye className='text-[#003049]' />
                                </button>
                                {val.status === "Draft" || val.status === "Perbaikan" ?
                                    <button
                                        onClick={() => {
                                            !val.bulan ?
                                                navigate('/laporan-kinerja/detail-saya/realisasi-periode', {
                                                    state: { idLaporan: val?.id, id: idSkp }
                                                }) :
                                                navigate('/laporan-kinerja/detail-saya/realisasi-bulanan', {
                                                    state: { idLaporan: val?.id, id: idSkp }
                                                })
                                        }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-blue-thick' />
                                    </button>
                                    :
                                    <button disabled className='w-[29px] h-[29px] bg-[#b3c3ce] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-blue-thick' />
                                    </button>
                                }
                                <button
                                    onClick={() => {
                                        !val.bulan ?
                                            deleteModalPeriode(val.id) :
                                            deleteModal(val.id)
                                    }} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#003049" />
                                    </svg>
                                </button>
                                {/* {val.status === "pending" || val.status === "perbaiki" ?
                                    <button disabled className='w-[29px] h-[29px] bg-[#b3c3ce] rounded-[9px] flex items-center justify-center'>
                                        <BiExport className='text-blue-thick' />
                                    </button>
                                    : <a
                                        href={!val.bulan ?
                                            `/export-penilain-kinerja-tahunan/${val.id}`
                                            : `/export-laporan-kinerja-bulanan/${val.id}`
                                        }
                                        target='_blank'
                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <BiExport className='text-[#003049]' />
                                    </a>
                                } */}
                            </div>
                        </div>
                    ))}
                </table>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    lengthData={totalCount}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </>
    )
}

export default RealisasiBuktiDukung