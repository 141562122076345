import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineDownload, AiOutlineEye } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { Pagination } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'

const ApprovalPAK = () => {
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalData, setTotalData] = useState('')
    const [limit, setLimit] = useState(10)

    const [data, setData] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getApproval = async () => {
        try {
            const response = await Api.GetApprovalPengajuanPak(localStorage.getItem('hris-token'), currentPage, limit)
            console.log(response.data.results)
            setData(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
            setTotalData(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getApproval()
        setRefresh(false)
    }, [refresh])
    return (
        <div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Periode SKP</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Periode Laporan</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status Verifikasi Administrasi</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan Verifikator Administrasi</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal Pengajuan</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal Selesai Verifikasi</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Verifikator</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tim Penilai</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status Verifikasi Berkas Angka Kredit</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px] w-full'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                        </div>
                    </div>
                    {data && data.map((val, index) => (
                        <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.user?.fullname ? val?.user?.fullname : '-'}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{moment(val?.sasaranKinerjaPegawai?.start_periode).format("D MMMM YYYY")} -{moment(val?.sasaranKinerjaPegawai?.end_periode).format("D MMMM YYYY")}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{moment(val?.laporanTahunan?.start_date).format("D MMMM YYYY")} -{moment(val?.laporanTahunan?.end_date).format("D MMMM YYYY")}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className={`py-[12px] px-[12px] text-xs text-center font-bold rounded-lg capitalize`}>{val?.statusVerifikasiAdministrasi ?? '-'}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.keteranganVerifikatorAdministrasi || val?.keteranganVerifikatorAdministrasi !== '' ? val?.keteranganVerifikatorAdministrasi : "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.createdAt ? moment(val?.createdAt).format("D MMMM YYYY") : "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.tanggalSelesaiVerifikasi ? moment(val?.tanggalSelesaiVerifikasi).format("D MMMM YYYY") : "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.kelompokKerjaVerifikatorId ? val?.kelompokKerjaVerifikator?.user?.fullname : "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.kelompokKerjaId ? val?.kelompokKerja?.timPenilai?.fullname : "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className={`py-[12px] px-[12px] text-xs text-center font-bold rounded-lg capitalize `}>{val?.statusVerifikasiBerkas ?? "-"}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] text-center truncate'>{val?.keterangan || val?.keterangan !== '' ? val?.keterangan : "-"}</h1>
                            </div>
                            <div className='flex flex-wrap items-center w-full gap-3'>
                                <button
                                    onClick={() => navigate(`/verifikasi/detail-pak/${val?.id}/${val?.laporanTahunan?.id}`, {
                                        state: { id: val?.kelompokKerja?.timPenilaiId }
                                    })}
                                    className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                    <HiOutlinePencil className='text-blue-thick' />
                                    <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Verifikasi</span>
                                </button>
                                {
                                    val?.isSigned ?
                                        <>
                                            <a href={imageHandle(val?.dokumenKumulatif?.path)} download={val?.dokumenKumulatif?.file_name} target='_blank' className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit' rel="noreferrer">
                                                <AiOutlineDownload className='text-sm text-blue-thick' />
                                                <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Dokumen PAK</span>
                                            </a>
                                            <a href={imageHandle(val?.dokumenPeriode?.path)} download={val?.dokumenPeriode?.file_name} target='_blank' className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit' rel="noreferrer">
                                                <AiOutlineDownload className='text-sm text-blue-thick' />
                                                <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Dokumen PAK Kumulatif</span>
                                            </a>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    ))}
                </table>
            </div>
            <div className='px-[14px] py-[8px]'>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    lengthData={totalData}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default ApprovalPAK
