import React, { useState } from 'react'
import { Navbar } from '../../../../../components';
import Pengajuan from './pengajuan';
import Riwayat from './riwayat';

const AngkaKredit = () => {
    const [menu, setMenu] = useState('Pengajuan PAK')

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengajuan PAK'} LinkBack={'/laporan-kinerja'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex justify-start items-center text-[16px]'>
                        <button onClick={() => setMenu('Pengajuan PAK')} className={`${menu === 'Pengajuan PAK' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Pengajuan PAK</button>
                        <button onClick={() => setMenu('Riwayat Angka Kredit')} className={`${menu === 'Riwayat Angka Kredit' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Riwayat Angka Kredit</button>
                    </div>

                    {menu === 'Pengajuan PAK' ? (
                        <Pengajuan />
                    ) : menu === 'Riwayat Angka Kredit' ? (
                        <Riwayat />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default AngkaKredit
